import React from 'react';
import { css, cx } from 'emotion';
import { useFormikField } from '../../utils/use-formik-field';

const inputContainerStyle = css`
  margin: 5px 0 1rem 0;
`;

const errorStyle = css`
  color: var(--error);

  font-size: 0.75rem;
  line-height: 1.5em;
  margin-left: 1rem;
`;

const groupIconButtonStyle = css`
  position: absolute !important;
  right: 0.75rem;
  background-color: transparent;
  border: none;
  height: 100%;
  padding: 0;
`;

const errorBorderStyle = css`
  border: 2px solid var(--error);
`;

const disabledStyle = css`
  border-color: #b9b9b9 !important;
  background-color: #b9b9b9 !important;
  color: white !important;
`;

const customInputStyle = css`
  width: 100%;
  height: 100%;
  padding: 0.625rem 1rem;
  box-sizing: border-box;

  font-size: 1rem;
  line-height: 1.375rem;
  border: none !important;
  border-radius: 0;
  background-color: transparent;
  position: absolute;
  outline: none !important;
  color: var(--dark);
  left: 0;
  top: 0;
  &:focus {
    + label {
      background-color: var(--white);
      width: auto;
      color: var(--primaryBrand);
      transition: all 0.25s ease-out;
      padding: 0 6px;
      transform: translate(-0.5rem, -1.9rem);
      font-size: 0.75rem;
      z-index: 2;
    }
    ~ .borderDiv {
      outline-color: var(--primaryBrand);
    }
  }
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
`;

const groupButtonStyle = css`
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--primaryBrand);
  border: none;
  margin-left: 1rem;
  height: 2.875rem;
  padding: 0 1rem;
  background: transparent;
  display: inline;
  &:focus {
    outline: none;
  }
`;

const addOnLabelStyle = css`
  border-bottom: 2px solid var(--primaryBrand);
`;

const stretchedContainerStyle = css`
  display: flex;
  align-items: stretch;
  position: relative;
`;

const labelStyle = css`
  width: 90%;
  position: absolute;
  transition: transform 0.25s ease-out;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--white);
  line-height: 1rem;

  font-size: 1rem;
  color: var(--darkGray);
  pointer-events: none;
  padding: 8px 0;
`;

const customInputContainer = css`
  position: relative;
  border: 2px solid var(--gray);
  flex: 1;
  height: 2.875rem;
`;

const hasValueState = css`
  label {
    color: var(--darkGray);
    background-color: var(--white);
    width: auto;
    transition: all 0.25s ease-out;
    padding: 0 6px;
    transform: translate(-0.5rem, -1.9rem);
    font-size: 0.75rem;
    z-index: 2;
  }
`;

const borderStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  outline: 2px solid transparent;
  pointer-events: none;
`;

export const InputComponent = props => {
  const {
    name,
    id,
    onChange,
    value,
    type,
    error,
    placeholder,
    onAddOnClick,
    addOnLabel,
    onBlur,
    touched,
    disabled,
    min,
    onChangeSideEffect,
    required,
    onFocus,
    focused,
    addOnIcon
  } = props;

  const handleChange = e => {
    onChangeSideEffect(e.target.value);
    onChange(e);
  };

  return (
    <div className={inputContainerStyle}>
      <div className={stretchedContainerStyle}>
        <div className={cx(customInputContainer, value && hasValueState, error && touched && errorBorderStyle)}>
          <input
            type={type}
            name={name}
            id={id}
            value={value}
            onChange={onChangeSideEffect ? handleChange : onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            min={min}
            required={required}
            className={disabled ? disabledStyle : customInputStyle}
          />
          <label className={labelStyle} htmlFor={id}>
            {placeholder}
          </label>
          <div className={cx(borderStyle, 'borderDiv')} />
        </div>
        {onAddOnClick && addOnLabel && (
          <div>
            <button type="button" onClick={onAddOnClick} disabled={disabled} className={groupButtonStyle}>
              <div className={addOnLabelStyle}>{addOnLabel}</div>
            </button>
          </div>
        )}
        {onAddOnClick && addOnIcon && (
          <div>
            <button
              type="button"
              onClick={e => onAddOnClick(e, !value)}
              disabled={disabled}
              className={groupIconButtonStyle}
            >
              <img src={addOnIcon} alt="extra" />
            </button>
          </div>
        )}
      </div>
      {error && touched && !focused && <div className={errorStyle}>{error}</div>}
    </div>
  );
};

export const InputField = props => {
  const {
    label,
    name,
    id,
    type,
    placeholder,
    onAddOnClick,
    addOnLabel,
    disabled,
    min,
    onChangeSideEffect,
    required,
    fixedValue,
    addOnIcon
  } = props;

  const { value, onBlur, setValue, isInErrorState, errorMessage, touched, onFocus, focused } = useFormikField(name);

  return (
    <InputComponent
      name={name}
      id={id}
      label={label}
      type={type}
      value={fixedValue || value || ''}
      invalid={isInErrorState}
      error={errorMessage}
      onChange={setValue}
      placeholder={placeholder}
      onAddOnClick={onAddOnClick}
      addOnLabel={addOnLabel}
      addOnIcon={addOnIcon}
      onBlur={onBlur}
      onFocus={onFocus}
      focused={focused}
      touched={touched}
      disabled={disabled}
      min={min}
      onChangeSideEffect={onChangeSideEffect}
      required={required}
    />
  );
};
