import React from 'react';
import { css } from 'emotion';
import { Card } from '../components/Card';
import { NewPolicyCard } from '../components/NewPolicyCard';
import { Loader } from '../components/Loader';
import { useUserPolicies } from '../../api/api';

const bodyContainer = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  padding: 2.2rem;
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const cardContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
  flex: 1;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const titleStyle = css`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 2.125rem;
`;

export const MyPolicies = () => {
  const { isLoading, data: policies } = useUserPolicies();

  return (
    <div className={bodyContainer}>
      <h1 className={titleStyle}>My Policies</h1>
      <div className={cardContainer}>
        {isLoading ? (
          <Loader />
        ) : policies.length > 0 ? (
          <>
            {policies.map(policy => (
              <Card policy={policy} key={policy.policyNumber} />
            ))}
            <NewPolicyCard />
          </>
        ) : (
          <NewPolicyCard />
        )}
      </div>
    </div>
  );
};
