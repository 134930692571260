import React, { useState } from 'react';
import { css } from 'emotion';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Accordion } from '../components/Accordion';
import { InputField } from '../components/InputField';
import { Button } from '../components/Button';
import { useFirebase } from '../Firebase';
import { Loader } from '../components/Loader';
import successIcon from '../../assets/successMessageIcon.png';
import errorIcon from '../../assets/errorMessageIcon.png';
import { MarketingAccordion } from '../components/MarketingAccordion';
import { useGetUserSettings } from '../../api/api';

const bodyContainer = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  padding: 2.2rem;
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const titleStyle = css`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 2.125rem;
`;

const buttonContainer = css`
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
`;

const messageStyle = css`
  font-size: 0.75rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

const iconStyle = css`
  height: 1.5rem;
  margin-right: 6px;
`;

const initialValues = {
  oldPassword: '',
  newPassword: '',
  repeatNewPassword: ''
};

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old password is required field'),
  newPassword: Yup.string().required('New password is required field'),
  repeatNewPassword: Yup.string().required('Repeated new password is required field')
});

export const MyAccount = () => {
  const firebase = useFirebase();
  const { auth } = useFirebase();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(undefined);
  const [userSettings, setUserSettings] = useState();
  const { isLoading: settingsLoading, data: settings } = useGetUserSettings(firebase);
  const handleSubmit = async ({ oldPassword, newPassword, repeatNewPassword }) => {
    try {
      setIsLoading(true);
      setSuccess(false);
      setErrorText(undefined);
      setError(false);
      if (oldPassword && newPassword && repeatNewPassword && newPassword === repeatNewPassword) {
        await firebase.doReauthenticateWithCredential(oldPassword);
        await auth.currentUser.updatePassword(newPassword);
      }
      if (newPassword !== repeatNewPassword) {
        setError(true);
        setErrorText('New password and Repeat New Password need to be the same! Please check your data and try again.');
      } else {
        setSuccess(true);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err.message);
      setErrorText(err.message);
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <div className={bodyContainer}>
      <h1 className={titleStyle}>My Account</h1>
      <Accordion
        title="Change Password"
        body={
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {formik => (
                  <div>
                    <InputField type="password" name="oldPassword" id="oldPassword" placeholder="Old Password" />
                    <InputField type="password" name="newPassword" id="newPassword" placeholder="New Password" />
                    <InputField
                      type="password"
                      name="repeatNewPassword"
                      id="repeatNewPassword"
                      placeholder="Repeat New Password"
                    />
                    <div className={buttonContainer}>
                      <Button onClick={formik.handleSubmit} label="Change Password" />
                    </div>
                    {success ? (
                      <div className={messageStyle}>
                        <img src={successIcon} className={iconStyle} alt="success icon" />
                        Your password has successfully been changed!
                      </div>
                    ) : (
                      ''
                    )}
                    {error ? (
                      <div className={messageStyle}>
                        <img src={errorIcon} className={iconStyle} alt="error icon" />
                        {errorText ||
                          'An error has occurred, please check if your Old Password is correct and try again.'}
                        <br />
                      </div>
                    ) : (
                      ''
                    )}
                    <div className={messageStyle}>
                      <img src={errorIcon} className={iconStyle} alt="error icon" />
                      If you signed up with Continue with google button, you can sign in with the same button or go to
                      Forget password button on Sign in page and create a password for your account.
                    </div>
                  </div>
                )}
              </Formik>
            )}
          </>
        }
      />
      <MarketingAccordion
        isLoading={settingsLoading}
        setUserSettings={setUserSettings}
        userSettings={userSettings || settings}
      />
    </div>
  );
};
