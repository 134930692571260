import React, { useState } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { css } from 'emotion';
import { Button } from '../components/Button';
import { BEAUTY, COVEATRADESMAN, COVERMYTOOLS, PBIS, schemeInformation } from '../../constants/products';
import useFirebase from '../Firebase/useFirebase';
import { Modal } from '../components/Modal';

const linkStyle = css`
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;
const buttonsContainer = css`
  padding: 1.5rem;
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const buttonDivider = css`
  width: 1rem;
  height: 1rem;
`;

const customModalStyle = css`
  @media (min-width: 768px) {
    border-top: 2px solid var(--primaryBrand);
    max-width: 43.75rem;
    height: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
`;

const numberStyle = css`
  color: var(--primaryBrand);
  text-decoration: none;
  font-weight: bold;
  position: relative;
  &:hover {
    text-decoration: none;
    color: var(--primaryBrandDark);
  }
  &:hover:after {
    opacity: 1;
    width: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    height: 0.125rem;
    left: 50%;
    width: 0;
    opacity: 0;
    background: var(--primaryBrandDark);
    transform: translateX(-50%);
    transition: width 0.25s, opacity 0.25s;
  }
`;

export const PolicyButtonsContainer = props => {
  const { policy } = props;
  const { auth } = useFirebase();
  const checkDatatagDate = () => moment().diff(moment(policy.coverStartDate), 'hours') > 72;
  const encryptedPolicyId = get(policy, 'encryptedId');
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {!policy.isCancelled && (
        <div className={buttonsContainer}>
          {![BEAUTY, COVEATRADESMAN, PBIS].includes(policy.productCode) && (
            <a
              className={linkStyle}
              href={schemeInformation[policy.productCode].claimUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button label="Report a claim" />
            </a>
          )}
          {PBIS === policy.productCode && (
            <Button
              onClick={() => {
                setShowModal(true);
              }}
              label="Report a claim"
            />
          )}
          <div className={buttonDivider} />
          <a
            className={linkStyle}
            href={schemeInformation[policy.productCode].mtaUrl(encryptedPolicyId, get(auth, 'currentUser.uid'))}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button label="Make a change" secondary />
          </a>
          <div className={buttonDivider} />
          {policy.isReadyForRenewal && (
            <a
              className={linkStyle}
              href={schemeInformation[policy.productCode].renewalUrl(encryptedPolicyId, get(auth, 'currentUser.uid'))}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button label="Renew policy" secondary />
            </a>
          )}
          {policy.productCode === COVERMYTOOLS && checkDatatagDate() && (
            <>
              <div className={buttonDivider} />
              <a
                className={linkStyle}
                href="https://insyncinsurance.formstack.com/forms/covermytools_mta_request"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button label="Business partner details" secondary />
              </a>
              <div className={buttonDivider} />
              <a
                className={linkStyle}
                href="https://www.datatag.mobi/mydatatag/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button label="DATATAG TOOLS" secondary />
              </a>
            </>
          )}
        </div>
      )}
      {showModal && (
        <Modal
          title="Report a claim"
          handleClose={() => {
            setShowModal(false);
          }}
          customModalStyle={customModalStyle}
          show={showModal}
        >
          <p>
            If you need to report a loss or wish to make a claim, please call us on{' '}
            <a href="tel:01242 508960" className={numberStyle}>
              01242 508960
            </a>{' '}
          </p>
        </Modal>
      )}
    </>
  );
};
