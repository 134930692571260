import React, { useEffect } from 'react';
import { css } from 'emotion';
import '../../index.css';
import { Route, Switch, matchPath, useHistory, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { observer } from 'mobx-react';
import { Footer } from '../components/Footer';
import { LeftSidebar } from '../components/LeftSidebar';
import { RightSidebar } from '../components/RightSidebar';
import { MyPolicies } from '../MyPolicies/MyPolicies';
import { MyQuotes } from '../MyQuotes/MyQuotes';
import { MyBenefits } from '../MyBenefits/MyBenefits';
import { useFirebase } from '../Firebase';
import { SignIn } from '../SignIn/SignIn';
import { MyPolicy } from '../MyPolicy/MyPolicy';
import { lookupStore } from '../../store/lookup-store';
import { Header } from '../components/Header';
import { MyAccount } from '../MyAccount/MyAccount';
import { CommonQuestions } from '../CommonQuestions/CommonQuestions';
import { ContactUs } from '../ContactUs/ContactUs';
import { SignUp } from '../SignUp/SignUp';
import { ResetPassword } from '../ResetPassword/ResetPassword';
import { Success } from '../Success/Success';
import { Error } from '../Error/Error';
import { ClaimPolicy } from '../ClaimPolicy/ClaimPolicy';
import { ClaimQuote } from '../ClaimQuote/ClaimQuote';
import { MyQuote } from '../MyQuote/MyQuote';
import { userStore } from '../../store/user-store';
import { useLookups } from '../../api/api';
import { Loader } from '../components/Loader';

const bodyStyle = css`
  height: 100%;
  display: grid;
  grid-template-columns: 15rem auto 22rem;
  grid-template-rows: auto 18rem;
  @media (max-width: 767px) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
  }
  @media (max-width: 350px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }
`;

const containerStyle = css`
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 18rem;
`;

const contentContainer = css`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(36, 36, 39, 0.04);
  color: black;
  @media (max-width: 767px) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
`;

export const App = observer(() => {
  const { auth } = useFirebase();
  const [user] = useAuthState(auth);
  const { isLoading, data } = useLookups();
  if (data) {
    lookupStore.setLookups(data);
  }

  const { ppsPending } = userStore;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (ppsPending && ppsPending.length !== 0) {
      const match = matchPath(location.pathname, {
        path: '/policies/:policyId',
        exact: true
      });

      if (!match) {
        history.replace(`policies/${String(ppsPending[0]?.policyNumber)}?paymentPageStatement=Not%20Captured`);
      }
    }
  }, [ppsPending, location.pathname, history]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {user ? (
        <>
          <Switch>
            <Route path="/claim-policy/:id?" component={ClaimPolicy} />
            <Route path="/claim-quote/:id?" component={ClaimQuote} />
            <>
              <div className={bodyStyle}>
                <Header />
                <LeftSidebar />
                <div className={contentContainer}>
                  {/* <Maintenance/> */}
                  <Switch>
                    <Route exact path="/" component={MyPolicies} />
                    <Route exact path="/policies" component={MyPolicies} />
                    <Route exact path="/quotes" component={MyQuotes} />
                    <Route path="/quotes/:quoteRef" component={MyQuote} />
                    {userStore.isInsyncCustomer && <Route path="/benefits" component={MyBenefits} />}
                    <Route path="/benefits" component={MyBenefits} />
                    <Route path="/policies/:policyId" component={MyPolicy} />
                    <Route path="/myAccount" component={MyAccount} />
                    <Route path="/commonQuestions" component={CommonQuestions} />
                    <Route path="/contactUs" component={ContactUs} />
                    <Route path="/error" component={Error} />
                  </Switch>
                  {/* <SalesBanner/> */}
                </div>
                <RightSidebar />
                <Footer />
              </div>
            </>
          </Switch>
        </>
      ) : (
        <>
          <div className={containerStyle}>
            <Switch>
              <Route path="/signUp" component={SignUp} />
              <Route path="/resetPassword" component={ResetPassword} />
              <Route path="/success" component={Success} />
              <Route path="/error" component={Error} />
              <Route component={SignIn} />
            </Switch>
            <Footer />
          </div>
        </>
      )}
    </>
  );
});
