import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { App } from './components/App/App';
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './components/Firebase';

const firebase = new Firebase();
const queryClient = new QueryClient();
if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://7856188097564e2480ebccbaa9325aea@sentry-insync.coreline.hr/7'
  });
}
ReactDOM.render(
  <FirebaseContext.Provider value={firebase}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
