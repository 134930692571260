import { observable } from 'mobx';

export class UserStore {
  @observable
  userName = undefined;

  @observable
  isInsyncCustomer = undefined;

  @observable
  ppsPending = undefined;

  // eslint-disable-next-line no-return-assign
  setUserName = userName => (this.userName = userName);
}

export const userStore = new UserStore();
