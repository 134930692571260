import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { useFirebase } from '../components/Firebase';
import { userStore } from '../store/user-store';
import { CUSTOMER_PORTAL } from '../constants/products';

export const cacheKeys = {
  me: 'me',
  lookups: 'lookups',
  quote: 'quote',
  policy: 'policy',
  quotes: 'quotes',
  policies: 'policies',
  claimPolicy: 'claimPolicy',
  claimQuote: 'claimQuote'
};

export const useLookups = () => {
  const firebase = useFirebase();
  return useQuery([cacheKeys.lookups], () => firebase.doGetLookups(CUSTOMER_PORTAL), {
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess: async () => {
      userStore.isInsyncCustomer = await firebase.doIsInsyncCustomer();
    },
    onError: err => {
      console.error(err);
      Sentry.captureException(err);
    }
  });
};

export const useUserPolicies = () => {
  const firebase = useFirebase();
  const history = useHistory();
  return useQuery([cacheKeys.policies], firebase.doGetUserPolicies, {
    cacheTime: Infinity,
    onSuccess: result => {
      const ppsPending = result.filter(x => x.paymentPageStatement === 'Not Captured');
      if (ppsPending) userStore.ppsPending = ppsPending;
    },
    onError: err => {
      console.error(err);
      Sentry.captureException(err);
      history.push({ pathname: '/error', state: { message: err.message } });
    }
  });
};

export const updateMarketingOptions = async (firebase, formik) => {
  const { auth } = firebase;
  const { values } = formik;
  firebase.doUpdateMarketingOptions({
    email: auth.currentUser.email,
    marketingOptions: JSON.stringify(values.marketing)
  });
};

export const useUserQuotes = () => {
  const firebase = useFirebase();
  const history = useHistory();
  return useQuery([cacheKeys.quotes], firebase.doGetUserQuotes, {
    cacheTime: Infinity,
    onError: err => {
      console.error(err);
      Sentry.captureException(err);
      history.push({ pathname: '/error', state: { message: err.message } });
    }
  });
};

export const useUserQuote = quoteRef => {
  const firebase = useFirebase();
  const history = useHistory();
  return useQuery([cacheKeys.quote, quoteRef], () => firebase.doGetUserQuote({ quoteId: quoteRef }), {
    onError: err => {
      console.error(err);
      Sentry.captureException(err);
      history.push({ pathname: '/error', state: { message: err.message } });
    }
  });
};

export const useUserPolicy = policyId => {
  const firebase = useFirebase();
  const history = useHistory();
  return useQuery([cacheKeys.policy, policyId], () => firebase.doGetUserPolicy({ policyReference: policyId }), {
    onError: err => {
      console.error(err);
      Sentry.captureException(err);
      history.push({ pathname: '/error', state: { message: err.message } });
    }
  });
};

export const useClaimPolicy = policyId => {
  const firebase = useFirebase();
  const history = useHistory();
  return useQuery([cacheKeys.claimPolicy, policyId], () => firebase.doCheckPolicyClaimStatus({ id: policyId }), {
    onSuccess: async value => {
      if (value) {
        await firebase.doClaimPolicy({ id: policyId });
      }
      history.push('/');
    },
    onError: err => {
      console.error(err);
      history.push('/');
      Sentry.captureException(err);
    }
  });
};
export const useGetUserSettings = firebase => {
  const { auth } = firebase;

  return useQuery(
    [cacheKeys.userSettings, auth.currentUser.email],
    () => firebase.doGetUserSettings({ faces: '', email: auth.currentUser.email }),
    {
      onSuccess: async value => value,
      onError: err => {
        console.error(err);
        // history.push('/');
        Sentry.captureException(err);
      }
    }
  );
};

export const useClaimQuote = quoteRef => {
  const firebase = useFirebase();
  const history = useHistory();
  return useQuery([cacheKeys.claimQuote, quoteRef], () => firebase.doCheckQuoteClaimStatus({ id: quoteRef }), {
    onSuccess: async value => {
      if (value) {
        await firebase.doClaimQuote({ id: quoteRef });
      }
      history.push('/quotes');
    },
    onError: err => {
      console.error(err);
      history.push('/');
      Sentry.captureException(err);
    }
  });
};
