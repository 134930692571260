import React from 'react';
import { css, cx } from 'emotion';
import benefitsUrl from '../../assets/benefitsLogo.png';
import appleLogo from '../../assets/Apple Logo.svg';
import odeonLogo from '../../assets/Odeon Cinemas Ltd Logo.svg';
import tescoLogo from '../../assets/Tesco Logo.svg';
import argosLogo from '../../assets/Argos Logo.png';
import neroLogo from '../../assets/Caffe Nero Logo.png';
import whiteLogo from '../../assets/The White Company Logo.png';
import halfordsLogo from '../../assets/Halfords Logo.svg';
import bqlogo from '../../assets/B&Q Logo.svg';
import pizzaHutLogo from '../../assets/Pizza Hut Logo.svg';

const containerStyle = css`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const imgStyle = css`
  height: 2.5rem;
  margin-bottom: 1rem;
`;

const titleStyle = css`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 2rem;
`;

const textStyle = css`
  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  width: 63%;
  @media (max-width: 767px) {
    width: 90%;
  }
`;

const marginBottomS = css`
  margin-bottom: 1rem;
`;
const marginBottomM = css`
  margin-bottom: 2.875rem;
`;

const codeStyle = css`
  font-family: Source Code Pro, sans-serif;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.05em;
  color: #14c2cb;
`;

const buttonStyle = css`
  text-align: center;
  padding: 0.5rem 1.5rem;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5em;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  cursor: pointer;

  background: #2ee7b3;
  color: var(--white);
  @media (max-width: 767px) {
    width: 100%;
  }

  &:hover {
    color: var(--white);
    text-decoration: none;
  }
`;

const logoStyle = css`
  width: 6rem;
  height: 3rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

const logoContainer = css`
  width: 70%;
  margin-bottom: 1.125rem;
  margin-top: 0.625rem;
  @media (max-width: 767px) {
    width: 90%;
  }
`;

export const MyBenefits = () => (
  <div className={containerStyle}>
    <img src={benefitsUrl} alt="benefits logo" className={imgStyle} />
    <h1 className={titleStyle}>Sign up to your completely free benefits platform!</h1>
    <p className={cx(textStyle, marginBottomS)}>
      As a thank you for taking out your policy with Insync, we are giving you access to our exclusive benefits platform
      Insync Benefits.
    </p>
    <p className={cx(textStyle, marginBottomS)}>
      This is completely FREE to you, giving you unbelievable discounts and offers from some of the biggest High Street
      brands including:
    </p>
    <div className={logoContainer}>
      <img src={appleLogo} alt="apple logo" className={logoStyle} />
      <img src={odeonLogo} alt="odeon logo" className={logoStyle} />
      <img src={tescoLogo} alt="tesco logo" className={logoStyle} />
      <img src={argosLogo} alt="argos logo" className={logoStyle} />
      <img src={neroLogo} alt="caffe nero logo" className={logoStyle} />
      <img src={whiteLogo} alt="white company logo" className={logoStyle} />
      <img src={halfordsLogo} alt="halfords logo" className={logoStyle} />
      <img src={pizzaHutLogo} alt="pizza hut logo" className={logoStyle} />
      <img src={bqlogo} alt="b&q logo" className={logoStyle} />
    </div>
    <p className={cx(textStyle, marginBottomS)}>
      To get started please click the button below to find out more and use code the following code to sign up.
    </p>
    <p className={cx(codeStyle, marginBottomM)}>3TVPRE</p>
    <a href="https://insync.benefithub.com/" target="_blank" rel="noopener noreferrer" className={buttonStyle}>
      Get started
    </a>
  </div>
);
