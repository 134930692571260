import { css } from 'emotion';
import React from 'react';
import checkedRadioButton from '../../assets/checkedRadioButton.svg';
import unCheckedRadioButton from '../../assets/unCheckedRadioButton.svg';

const containerStyle = css`
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const inputStyle = css`
  display: none;
  &:checked {
    & + .checkStyle {
      border: 2px solid var(--white);
      border-radius: 50%;
      box-shadow: 0 0 0 2px var(--primaryBrand);
      background: var(--primaryBrand);
    }
  }
`;

const labelStyle = css`
  font-family: Lato, sans-serif;
  font-size: 0.875rem;
  color: var(--dark);
  margin-left: 0.75rem;
`;

export const RadioButtonComponent = props => {
  const { onChange, name, label, id, checked } = props;
  return (
    <label className={containerStyle} id={id}>
      <input type="radio" onChange={onChange} name={name} className={inputStyle} checked={checked} />
      <img src={checked ? checkedRadioButton : unCheckedRadioButton} alt="arrow" />

      <p className={labelStyle}>{label}</p>
    </label>
  );
};

export const CustomRadioButton = props => {
  const { label, name, id, checked, onChange } = props;

  return <RadioButtonComponent name={name} id={id} label={label} checked={checked} onChange={onChange} />;
};
