import React, { Fragment, useEffect, useRef, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { css, cx } from 'emotion';
import moment from 'moment';
import get from 'lodash/get';
import insyncLogo from '../../assets/insyncLogoSecundary.png';
import { AXIS_AESTHETIC, schemeInformation, BEAUTY } from '../../constants/products';
import { lookupStore } from '../../store/lookup-store';
import { Loader } from '../components/Loader';
import downloadIcon from '../../assets/downloadIcon.svg';
import { AxisAestheticTreatmentsDisplay } from '../components/AxisAestheticTreatmentsDisplay';
import { DocumentPolicyContainer } from './DocumentPolicyContainer';
import { PolicyButtonsContainer } from './PolicyButtonsContainer';
import { PolicyMessageDisclaimer } from './PolicyMessageDisclaimer';
import { useUserPolicy } from '../../api/api';
import { convertToBlob, downloadFile } from '../../utils/downloadFunctions';
import { Modal } from '../components/Modal';
import { DocumentButton } from '../components/DocumentButton';
import { useFirebase } from '../Firebase';
import { CustomRadioButton } from '../components/CustomRadioButton';
import { Button } from '../components/Button';
import { userStore } from '../../store/user-store';

const headerContainer = color => css`
  background: ${color};
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  @media (max-width: 767px) {
    border-radius: 8px;
    padding: 1.25rem 1.5rem 2rem 1.5rem;
  }
`;

const textDescriptionStyle = css`
  font-size: 1rem;
  line-height: 1.5em;
  color: var(--dark);
  margin-bottom: 1rem;
  > strong {
    font-weight: 600;
    color: var(--primaryBrand);
  }
`;

const inputDescriptionStyle = css`
  font-size: 1rem;
  line-height: 1.5em;
  color: var(--dark);
  margin-block: 1rem;
`;

const linkStyle = css`
  text-decoration: underline;
  color: var(--primaryBrand);
  cursor: pointer;
  display: inline-flex;
  margin-inline: 0.1rem 0.2rem;
  justify-content: baseline;

  > img {
    display: none;
  }

  &:hover .policyTextStyle:after {
    opacity: 0;
    width: 100%;
  }

  &::before {
    content: url(${downloadIcon});
    margin-right: 0.1rem;
  }
`;

const containerStyle = css`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: var(--white);
  margin-right: 2rem;
  margin-left: 2rem;
  margin-bottom: 3rem;
  @media (max-width: 767px) {
    margin-right: 1rem;
    margin-left: 1rem;
    border-radius: 8px;
  }
`;

const categoryText = css`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: -0.03em;
  color: var(--white);
  opacity: 0.64;
`;

const rowStyle = css`
  margin-bottom: 1rem;
`;

const insuranceNameStyle = css`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.375rem;
  letter-spacing: -0.03em;
  color: var(--white);
  margin-left: 0.75rem;
`;

const inlineBlock = css`
  display: inline-block;
`;

const policyNumberStyle = css`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.375rem;
  letter-spacing: -0.03em;
  color: var(--white);
`;

const bottomRow = css`
  margin-top: 0.25rem;
  display: flex;
  justify-content: space-between;
`;

const logoStyle = css`
  height: 2.5rem;
  @media (max-width: 767px) {
    display: none;
  }
`;

const infoRowStyle = css`
  display: flex;
  border-bottom: 0.5px solid var(--gray);
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const constantInfo = css`
  flex: 1;
  padding: 1.5rem;
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;
const specificInfo = color => css`
  flex: 1;
  padding: 1.5rem;
  background: ${color};
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const categoryStyle = css`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  opacity: 0.64;
  margin-bottom: 0.25rem;
`;
const valueStyle = css`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 1rem;
`;

const tableCategory = css`
  min-width: 9.5rem;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  opacity: 0.64;
  padding-bottom: 1rem;
  @media (max-width: 767px) {
    min-width: 5.3125rem;
    font-size: 0.75rem;
    line-height: 1.17rem;
  }
`;

const tableValue = css`
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  padding-bottom: 1rem;
  @media (max-width: 767px) {
    font-weight: normal;
  }
`;

const tableStyle = css`
  max-width: 100%;
`;

const customModalWrapperStyle = css`
  height: 100vh;
  border-radius: unset;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    border-radius: 12px;
    border-top: 2px solid var(--primaryBrand);
    max-width: 43.75rem;
    height: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
`;

const customModalBodyStyle = css`
  flex: 1;
  flex-basis: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
`;

const SOFForm = props => {
  const { policy, onSubmit } = props;
  const firebase = useFirebase();
  const [checked, setChecked] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handlePolicyDocumentDownload = async (docId, downloadMethod) => {
    try {
      const { base64Content, contentType, filename } = await downloadMethod(docId);
      const blob = convertToBlob(base64Content, contentType);
      downloadFile(blob, filename);
    } catch (err) {
      console.error(err);
    }
  };

  const handleConsentUpdate = async () => {
    if (checked === undefined) return;
    setLoading(true);
    try {
      await firebase.doUpdateDocumentConsent({
        policyReference: policy.policyNumber,
        SalesforceID: policy.SalesforceID,
        value: checked
      });
      userStore.ppsPending = userStore.ppsPending.filter(x => x.policyNumber !== policy.policyNumber);
    } catch (err) {
      setError(true);
    }
    setLoading(false);
    onSubmit();
  };

  const documents = policy.policyDocuments;
  const sofDocId =
    documents &&
    documents.find(x => get(x, 'type.code') === 'NB' && get(x, 'subType.code') === 'NB_DOC_ACCEPT_SOF')?.id;
  const summaryDocId =
    documents &&
    documents.find(x => get(x, 'type.code') === 'NB' && get(x, 'subType.code') === 'NB_DOC_ACCEPT_SUMMARY')?.id;

  return (
    <>
      {' '}
      <p className={textDescriptionStyle}>
        Renewal for policy <strong>{policy.policyNumber}</strong> is approaching so please review important policy
        documents before continuing
      </p>
      <p className={textDescriptionStyle}>
        Please review your policy documents, these should be read in conjunction. You are responsible for ensuring that
        information is accurate and your policy still meets your demands and needs. If details are inaccurate please
        contact us on <b>01200 309 516</b> as soon as possible. If details are not correct claims may be impacted or not
        paid.{' '}
      </p>
      <p className={textDescriptionStyle}>
        By renewing your policy with us you confirm that you have reviewed these documents, are happy that details are
        accurate and the insurance meets your demands and needs.
      </p>
      <p className={textDescriptionStyle}>
        We have made some changes to the insurance products that we provide and will be moving your insurance provider
        to Altea at renewal. <b>You will find some differences between your old and new policies.</b> Please review the
        downloadable ‘summary of change’ document which details the key material changes to your cover.
        <br />
        <br />
        To purchase your renewal policy, you will need to agree with the statement of fact and summary of change shown
        below as downloadable documents.{' '}
        <b>Your statement of fact may have changed, and we may have made some assumptions.</b> It is important that you
        are happy your statement of fact is accurate and that you agree with changes made to your cover before renewing.
      </p>
      <p className={inputDescriptionStyle}>
        I have read and agree with the{' '}
        <span>
          <DocumentButton
            name="Statement of Fact"
            customStyle={linkStyle}
            onClick={() =>
              handlePolicyDocumentDownload(sofDocId, () => firebase.doDownloadDocument(sofDocId, 'POLICY'))
            }
          />
        </span>{' '}
        and{' '}
        <span>
          <DocumentButton
            name="Summary of Change"
            customStyle={linkStyle}
            onClick={() =>
              handlePolicyDocumentDownload(summaryDocId, () => firebase.doDownloadDocument(summaryDocId, 'POLICY'))
            }
          />
        </span>{' '}
      </p>
      <CustomRadioButton
        id="yes"
        name="AcceptTerms"
        checked={checked === true}
        onChange={() => setChecked(true)}
        label="Yes"
      />
      <CustomRadioButton
        id="no"
        name="AcceptTerms"
        checked={checked === false}
        onChange={() => setChecked(false)}
        label="No"
      />
      <br />
      <Button loading={loading} label={error ? 'Try again' : 'Submit'} onClick={handleConsentUpdate} />
    </>
  );
};

const getAddress = policyholder => {
  const { address } = policyholder;
  const { Line1, Line2, PostTown, Postcode } = address;

  if (Line2) return <>{`${Line1}, ${Line2}, ${PostTown}, ${Postcode}`}</>;

  return <>{`${Line1}, ${PostTown}, ${Postcode}`}</>;
};

export const MyPolicy = withRouter(props => {
  const { policyId } = props.match.params;
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [treatmentsExpanded, setTreatmentsExpanded] = useState(false);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const policyDescriptionRef = useRef(null);
  const { isLoading, data: policy } = useUserPolicy(policyId);

  const loaderDivHeight = css`
    margin-top: 1rem;
    height: ${policyDescriptionRef.current?.offsetHeight - 16}px;
  `;

  useEffect(() => {
    if (
      policy?.SalesforceID &&
      ((userStore.ppsPending && userStore.ppsPending.find(x => x.policyNumber === policyId)) ||
        searchParams.get('paymentPageStatement'))
    ) {
      setShowDocumentsModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy]);

  if (!policy) return <Loader />;

  return (
    <>
      {policy.productCode === BEAUTY && showDocumentsModal && (
        <Modal
          title="Document Review"
          customModalStyle={customModalWrapperStyle}
          customBodyStyle={customModalBodyStyle}
          showCloseIcon={false}
          show
        >
          <SOFForm
            policy={policy}
            onSubmit={() => {
              setShowDocumentsModal(false);
              searchParams.delete('paymentPageStatement');
              history.push({ pathname: history.location.pathname });
            }}
          />
        </Modal>
      )}
      <div className={containerStyle}>
        <div className={headerContainer(get(schemeInformation[policy.productCode], 'headerColor') || '#ffc600')}>
          <div className={rowStyle}>
            <div className={cx(categoryText, inlineBlock)}>Insurance</div>
            <div className={cx(insuranceNameStyle, inlineBlock)}>
              {get(schemeInformation[policy.productCode], 'name')}
            </div>
          </div>
          <div className={categoryText}>Policy Number</div>
          <div className={bottomRow}>
            <div className={policyNumberStyle}>{policy.policyNumber}</div>
            <img
              src={get(schemeInformation[policy.productCode], 'headerLogo') || insyncLogo}
              alt="insyncLogo"
              className={get(schemeInformation[policy.productCode], 'headerStyle') || logoStyle}
            />
          </div>
        </div>
        <div>
          {isDownloadLoading || isLoading ? (
            <div className={loaderDivHeight}>
              <Loader message={isDownloadLoading ? 'Downloading your policy document...' : ''} />
            </div>
          ) : (
            <div className={infoRowStyle}>
              <div ref={policyDescriptionRef} className={constantInfo}>
                <table className={tableStyle}>
                  <tbody>
                    <tr>
                      <td className={tableCategory}>Start Date</td>
                      <td className={tableValue}>{moment(policy.coverStartDate).format('DD/MM/YYYY')}</td>
                    </tr>
                    <tr>
                      <td className={tableCategory}>End Date</td>
                      <td className={tableValue}>{moment(policy.coverEndDate).format('DD/MM/YYYY')}</td>
                    </tr>
                    <tr>
                      <td className={tableCategory}>Holder Name</td>
                      <td className={tableValue}>
                        {`${lookupStore.getTitleNameFromPolicyholder(policy.policyHolder)} ${
                          policy.policyHolder.firstName
                        } ${policy.policyHolder.lastName}`}
                      </td>
                    </tr>
                    <tr>
                      <td className={tableCategory}>Holder Email</td>
                      <td className={tableValue}>{policy.policyHolder.emailAddress}</td>
                    </tr>
                    <tr>
                      <td className={tableCategory}>Address</td>
                      <td className={tableValue}>{getAddress(policy.policyHolder)}</td>
                    </tr>
                    <tr>
                      <td className={tableCategory}>Phone Number</td>
                      <td className={tableValue}>{policy.policyHolder.telephone}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={specificInfo(get(schemeInformation[policy.productCode], 'specificInfoColor'))}>
                {Object.entries(schemeInformation[policy.productCode].specialCategoriesPolicy).map(([key, value]) => (
                  <Fragment key={key}>
                    <div className={categoryStyle}>{key}</div>
                    <div className={valueStyle}>{value(policy)}</div>
                  </Fragment>
                ))}
                {policy.productCode === AXIS_AESTHETIC && (
                  <AxisAestheticTreatmentsDisplay
                    treatmentsExpanded={treatmentsExpanded}
                    setTreatmentsExpanded={setTreatmentsExpanded}
                    quoteState={policy}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <DocumentPolicyContainer policy={policy} setIsLoading={setIsDownloadLoading} />
        <PolicyButtonsContainer policy={policy} />
        <PolicyMessageDisclaimer policy={policy} />
      </div>
    </>
  );
});
