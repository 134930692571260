import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { css, cx } from 'emotion';
import { Link, withRouter } from 'react-router-dom';
import useFirebase from '../Firebase/useFirebase';
import { Button } from '../components/Button';
import insyncLogo from '../../assets/insyncLogo.svg';
import { InputField } from '../components/InputField';
import { Loader } from '../components/Loader';

const bodyContainer = css`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  padding: 2.5rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    padding: 1.5rem 1rem;
  }
`;

const singUpContainer = css`
  width: 60%;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const imgStyle = css`
  align-self: flex-start;
  height: 2.5rem;
  margin-bottom: 5rem;
`;

const titleStyle = css`
  font-weight: 900;
  font-size: 4rem;
  line-height: 1.2em;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--primaryBrand);
  margin-bottom: 1.5rem;
  @media (max-width: 767px) {
    font-size: 2.25rem;
  }
`;

const buttonContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    flex: 1;
  }
`;

const registerLinkTextStyle = css`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2em;
  letter-spacing: -0.03em;
  color: #242427;
  text-align: center;
`;

const linkStyle = css`
  color: #242427;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:hover {
    text-decoration: none;
    color: #242427;
  }
  &:hover:after {
    opacity: 1;
    width: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    height: 2px;
    left: 50%;
    width: 0;
    opacity: 0;
    background: var(--primaryBrand);
    transform: translateX(-50%);
    transition: width 0.25s, opacity 0.25s;
  }
`;

const messageTextStyle = css`
  margin-bottom: 1.5rem;

  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: #242427;
  text-align: center;
`;

const initialValues = { email: '', password: '' };

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required field')
});

export const ResetPassword = withRouter(props => {
  const firebase = useFirebase();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async ({ email }) => {
    try {
      setIsLoading(true);
      await firebase.doSendPasswordResetEmail(email);
      props.history.push('/success');
      setIsLoading(false);
    } catch (error) {
      console.error(error.message);
      props.history.push('/error');
      setIsLoading(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {formik =>
        isLoading ? (
          <Loader />
        ) : (
          <div className={bodyContainer}>
            <img src={insyncLogo} alt="insyncLogo" className={imgStyle} />
            <div className={singUpContainer}>
              <h1 className={titleStyle}>Reset Password</h1>
              <div className={messageTextStyle}>
                Please enter the email address associated with your existing account.
              </div>
              <InputField type="email" name="email" id="email" placeholder="Email" />
              <div className={buttonContainerStyle}>
                <Button onClick={formik.handleSubmit} label="Submit" />
              </div>
              <div className={registerLinkTextStyle}>
                <Link className={cx(registerLinkTextStyle, linkStyle)} to="/" onClick={() => window.scrollTo(0, 0)}>
                  Back to Sign in
                </Link>
              </div>
            </div>
          </div>
        )
      }
    </Formik>
  );
});
