import React from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'emotion';
import successIcon from '../../assets/success.svg';
import insyncLogo from '../../assets/insyncLogo.svg';

const bodyContainer = css`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  padding: 2.5rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    padding: 1.5rem 1rem;
  }
`;

const singUpContainer = css`
  width: 60%;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const imgStyle = css`
  align-self: flex-start;
  height: 2.5rem;
  margin-bottom: 5rem;
`;

const titleStyle = css`
  font-weight: 900;
  font-size: 4rem;
  line-height: 1.2em;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--primaryBrand);
  margin-bottom: 1.5rem;
  @media (max-width: 767px) {
    font-size: 2.25rem;
  }
`;

const registerLinkTextStyle = css`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2em;
  letter-spacing: -0.03em;
  color: #242427;
  text-align: center;
  margin-bottom: 3.5rem;
`;

const linkStyle = css`
  color: #242427;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:hover {
    text-decoration: none;
    color: #242427;
  }
  &:hover:after {
    opacity: 1;
    width: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    height: 2px;
    left: 50%;
    width: 0;
    opacity: 0;
    background: var(--primaryBrand);
    transform: translateX(-50%);
    transition: width 0.25s, opacity 0.25s;
  }
`;

const successImage = css`
  height: 12rem;
  margin-bottom: 3.5rem;
`;

export const Success = () => (
  <div className={bodyContainer}>
    <img src={insyncLogo} alt="insyncLogo" className={imgStyle} />
    <div className={singUpContainer}>
      <img src={successIcon} alt="success" className={successImage} />
      <h1 className={titleStyle}>Success</h1>
      <div className={registerLinkTextStyle}>
        Your reset password link has successfully been sent to your email. To sign in again please click{' '}
        <Link className={cx(registerLinkTextStyle, linkStyle)} to="/" onClick={() => window.scrollTo(0, 0)}>
          here
        </Link>
        .
      </div>
    </div>
  </div>
);
