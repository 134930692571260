import React from 'react';
import { css } from 'emotion';
import insyncLogoUrl from '../../assets/insyncLogo.svg';
import facebookUrl from '../../assets/facebook.png';
import instagramUrl from '../../assets/instagram.png';

const footer = css`
  grid-column: 1 / 4;
  grid-row: 2 / 3;
  background: rgba(36, 36, 39, 0.04);
  display: flex;
  @media (max-width: 767px) {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
    flex-direction: column;
  }
`;

const infoContainerStyle = css`
  width: 15rem;
  height: 100%;
  padding: 3rem 2rem;
`;

const textContainerStyle = css`
  flex: 1;
  color: #242427;
  padding: 3rem;
  align-self: flex-end;
  font-size: 0.75rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
`;

const socialMediaContainerStyle = css`
  width: 22rem;
  height: 100%;
  padding: 3rem 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const imgStyle = css`
  height: 2.5rem;
`;

const infoTextStyle = css`
  margin-top: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: #242427;
`;

const socialMediaUrlStyle = css`
  height: 2rem;
  margin-left: 1rem;
`;

const linkStyle = css`
  color: #242427;
  &:hover {
    color: #242427;
    text-decoration: none;
  }
`;

export const Footer = () => (
  <footer className={footer}>
    <div className={infoContainerStyle}>
      <img src={insyncLogoUrl} alt="logo" className={imgStyle} />
      <div className={infoTextStyle}>
        Insync Insurance Solutions Ltd <br />9 Albany Park, Cabot Lane Poole, Dorset BH17 7BX
      </div>
      <div className={infoTextStyle}>
        t. 0330 124 0730 <br />
        e. hello@insyncinsurance.co.uk
      </div>
    </div>
    <div className={textContainerStyle}>
      © Insync Insurance Solutions Ltd 2018 All rights reserved. Insync Insurance Solutions Ltd is authorised &
      regulated by the Financial Conduct Authority where our reference number is 766691. Our registered office is 7th
      Floor, Corn Exchange, 55 Mark Lane, London, EC3R 7NE and we are registered in England under company number
      08810662. Should you have cause to complain, and you are not satisfied with our response to your complaint, you
      may be able to refer it to the Financial Ombudsman Service, which can be contacted as follows: The Financial
      Ombudsman Service Exchange Tower, London, E14 9SR | Tel: 0800 023 4567 or 01200 309516 |
      www.financial-ombudsman.org.uk |
      <a
        href="https://insyncinsurance.co.uk/bitnami/wordpress/wp-content/uploads/docs/Terms%20of%20Business.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className={linkStyle}
      >
        {' '}
        Terms of Business
      </a>
    </div>
    <div className={socialMediaContainerStyle}>
      <a href="https://en-gb.facebook.com/insyncinsurance/" target="_blank" rel="noopener noreferrer">
        <img alt="facebook" src={facebookUrl} className={socialMediaUrlStyle} />
      </a>
      <a href="https://www.instagram.com/insyncinsurance/" target="_blank" rel="noopener noreferrer">
        <img alt="instagram" src={instagramUrl} className={socialMediaUrlStyle} />
      </a>
    </div>
  </footer>
);
