import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { css, cx } from 'emotion';
import { Loader } from '../components/Loader';
import { Footer } from '../components/Footer';
import { useClaimPolicy } from '../../api/api';

const containerStyle = css`
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 90% 18rem;
`;

const bodyContainer = css`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  padding: 2.5rem 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    padding: 1.5rem 1rem;
  }
`;

const titleStyle = css`
  font-weight: 900;
  font-size: 4rem;
  line-height: 1.2em;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--primaryBrand);
  margin-bottom: 2.5rem;
  @media (max-width: 767px) {
    font-size: 2.25rem;
  }
`;

const textStyle = css`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2em;
  letter-spacing: -0.03em;
  color: #242427;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const linkStyle = css`
  color: #242427;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:hover {
    text-decoration: none;
    color: #242427;
  }
  &:hover:after {
    opacity: 1;
    width: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    height: 2px;
    left: 50%;
    width: 0;
    opacity: 0;
    background: var(--primaryBrand);
    transform: translateX(-50%);
    transition: width 0.25s, opacity 0.25s;
  }
`;

export const ClaimPolicy = withRouter(({ match }) => {
  const policyIdFound = match.params.id;
  const { isLoading, error } = useClaimPolicy(policyIdFound);
  return (
    <div className={containerStyle}>
      {isLoading || !error ? (
        <div className={bodyContainer}>
          <Loader message="Initializing your policy. Please wait." />
        </div>
      ) : (
        <div className={bodyContainer}>
          <div className={titleStyle}>An error occurred</div>
          {!policyIdFound && (
            <>
              <div className={textStyle}>
                This link didn&apos;t provide the needed ID for initializing your policy. If you need to add your bought
                policy to your portal try to open the link in the email you got after buying a policy again or contact
                our support.
              </div>
              <div className={textStyle}>
                If you don&apos;t have any new policies to add to the portal proceed to your policies by clicking{' '}
                <Link className={cx(textStyle, linkStyle)} to="/">
                  here
                </Link>
                .
              </div>
            </>
          )}
          {policyIdFound && error ? (
            <>
              <div className={textStyle}>
                Something went wrong with adding your new policy to your customer portal. Please try again later or
                contact our support.
              </div>
              <div className={textStyle}>
                To go to your other policies click{' '}
                <Link className={cx(textStyle, linkStyle)} to="/" onClick={() => window.scrollTo(0, 0)}>
                  here
                </Link>
                .
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      )}
      <Footer />
    </div>
  );
});
