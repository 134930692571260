import React from 'react';
import { css } from 'emotion';
import moment from 'moment';
import get from 'lodash/get';
import { COVERMYTOOLS, schemeInformation } from '../../constants/products';
import { Button } from '../components/Button';
import useFirebase from '../Firebase/useFirebase';

const buttonsContainer = css`
  padding: 1.5rem;
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const buttonDivider = css`
  width: 1rem;
  height: 1rem;
`;
const linkStyle = css`
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

export const QuoteButtonsContainer = props => {
  const { auth } = useFirebase();
  const { quoteState } = props;
  const encryptedQuoteRef = get(quoteState, 'encryptedId');
  const checkDatatagDate = () => moment().diff(moment(quoteState.coverStartDate), 'hours') > 72;

  return (
    <>
      {!quoteState.isCancelled && (
        <div className={buttonsContainer}>
          <a
            className={linkStyle}
            href={schemeInformation[quoteState.productCode].quoteUrl(encryptedQuoteRef, get(auth, 'currentUser.uid'))}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button label="Continue with purchase" />
          </a>
          {quoteState.productCode === COVERMYTOOLS && checkDatatagDate() && (
            <>
              <div className={buttonDivider} />
              <a
                className={linkStyle}
                href="https://insyncinsurance.formstack.com/forms/covermytools_mta_request"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button label="Business partner details" secondary />
              </a>
              <div className={buttonDivider} />
              <a
                className={linkStyle}
                href="https://www.datatag.mobi/mydatatag/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button label="DATATAG TOOLS" secondary />
              </a>
            </>
          )}
        </div>
      )}
    </>
  );
};
