import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import { css, cx } from 'emotion';
import { lookupStore } from '../../store/lookup-store';
import angleDown from '../../assets/angle-down-orange.svg';

const treatmentsControlText = css`
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-decoration-line: underline;
  color: var(--primaryBrand);
`;
const tryAgainContainer = css`
  display: flex;
  cursor: pointer;
  align-items: flex-start;
`;

const angleStyle = css`
  transition: 0.5s ease-out;
  transform: rotate(0deg);
`;
const rotatedAngleStyle = css`
  transform: rotate(180deg);
`;

const categoryStyle = css`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  opacity: 0.64;
  margin-bottom: 0.25rem;
`;
const valueStyle = css`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 1rem;
`;

const findAxisTreatmentName = d =>
  lookupStore.axisTreatments
    ? get(find(lookupStore.axisTreatments, e => e.code === get(d, 'dataPointData.Treatments.code')), 'value')
    : get(d, 'dataPointData.Treatments.code');

export const AxisAestheticTreatmentsDisplay = props => {
  const { quoteState, treatmentsExpanded, setTreatmentsExpanded } = props;
  return (
    <>
      <div className={categoryStyle}>Treatments</div>
      {quoteState.genericInsuredObject.length <= 5 &&
        quoteState.genericInsuredObject.map(d => (
          <div key={get(d, 'parentDataPointId')} className={valueStyle}>
            {findAxisTreatmentName(d)}
          </div>
        ))}
      {quoteState.genericInsuredObject.length >= 6 && (
        <div>
          {!treatmentsExpanded &&
            quoteState.genericInsuredObject.slice(0, 5).map(d => (
              <div key={get(d, 'parentDataPointId')} className={valueStyle}>
                {findAxisTreatmentName(d)}
              </div>
            ))}
          {treatmentsExpanded &&
            quoteState.genericInsuredObject.map(d => (
              <div className={valueStyle} key={get(d, 'parentDataPointId')}>
                {findAxisTreatmentName(d)}
              </div>
            ))}
          <div className={tryAgainContainer} onClick={() => setTreatmentsExpanded(!treatmentsExpanded)}>
            <p className={treatmentsControlText}>View {treatmentsExpanded ? 'less' : 'all'}</p>
            <img src={angleDown} alt="angle down" className={cx(angleStyle, treatmentsExpanded && rotatedAngleStyle)} />
          </div>
        </div>
      )}
    </>
  );
};
