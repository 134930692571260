import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { css, cx } from 'emotion';
import { Link, withRouter } from 'react-router-dom';
import useFirebase from '../Firebase/useFirebase';
import { Button } from '../components/Button';
import insyncLogo from '../../assets/insyncLogo.svg';
import googleLogo from '../../assets/googleLogo.png';
import { InputField } from '../components/InputField';
import { Loader } from '../components/Loader';
import errorIcon from '../../assets/errorMessageIcon.png';

const bodyContainer = css`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  padding: 2.5rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    padding: 1.5rem 1rem;
  }
`;

const singUpContainer = css`
  width: 60%;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const imgStyle = css`
  align-self: flex-start;
  height: 2.5rem;
  margin-bottom: 5rem;
`;

const titleStyle = css`
  font-weight: 900;
  font-size: 4rem;
  line-height: 1.2em;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--primaryBrand);
  margin-bottom: 1.5rem;
  @media (max-width: 767px) {
    font-size: 2.25rem;
  }
`;

const buttonContainerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    flex: 1;
  }
`;

const linkTextStyle = css`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2em;
  letter-spacing: -0.03em;
  color: #242427;
  text-align: center;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 1.5rem;
  }
  &:hover {
    color: #242427;
    text-decoration: none;
  }
  &:hover:after {
    opacity: 1;
    width: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    height: 2px;
    left: 50%;
    width: 0;
    opacity: 0;
    background: var(--primaryBrand);
    transform: translateX(-50%);
    transition: width 0.25s, opacity 0.25s;
  }
`;

const registerLinkTextStyle = css`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2em;
  letter-spacing: -0.03em;
  color: #242427;
  text-align: center;
`;

const googleButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.084), 0 1px 1px rgba(0, 0, 0, 0.168);
  width: 100%;
  height: 2.875rem;

  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #242427;

  &:hover {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.08), 0 1px 10px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const googleLogoStyle = css`
  height: 1.125rem;
  margin-right: 1.5rem;
`;

const signInTextStyle = css`
  margin: 1.5rem 0;

  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: #242427;
  text-align: center;
`;

const linkStyle = css`
  color: #242427;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:hover {
    text-decoration: none;
    color: #242427;
  }
  &:hover:after {
    opacity: 1;
    width: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    height: 2px;
    left: 50%;
    width: 0;
    opacity: 0;
    background: var(--primaryBrand);
    transform: translateX(-50%);
    transition: width 0.25s, opacity 0.25s;
  }
`;

const messageStyle = css`
  font-size: 0.75rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  margin: 0.5rem 0 1rem 0;
  display: flex;
  align-items: center;
`;

const iconStyle = css`
  height: 1.5rem;
  margin-right: 6px;
`;

const initialValues = { email: '', password: '' };

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required field'),
  password: Yup.string().required('Password is required field')
});

export const SignIn = withRouter(({ location }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const firebase = useFirebase();

  const handleSignInWithGoogle = async () => {
    await firebase.doSignInWithGoogle();
  };

  const handleSubmit = async ({ email, password }) => {
    try {
      setIsLoading(true);
      setError(false);
      setErrorMessage(undefined);
      await firebase.doSignInWithEmailAndPassword(email, password);
      setIsLoading(false);
    } catch (err) {
      setError(true);
      setErrorMessage(errorMessage);
      console.error(err.message);
      setIsLoading(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {formik =>
        isLoading ? (
          <Loader />
        ) : (
          <div className={bodyContainer}>
            <img src={insyncLogo} alt="insyncLogo" className={imgStyle} />
            <div className={singUpContainer}>
              <h1 className={titleStyle}>Welcome back.</h1>
              <div className={googleButtonStyle} onClick={handleSignInWithGoogle}>
                <img src={googleLogo} alt="googleLogo" className={googleLogoStyle} />
                Continue with google
              </div>
              <div className={signInTextStyle}>or sign in here</div>
              <InputField type="email" name="email" id="email" placeholder="Email" />
              <InputField type="password" name="password" id="password" placeholder="Password" />
              {error ? (
                <div className={messageStyle}>
                  <img src={errorIcon} className={iconStyle} alt="error icon" />
                  {errorMessage || 'An error occurred. Please check your login details and try again.'}
                </div>
              ) : (
                ''
              )}
              <div className={buttonContainerStyle}>
                <Button onClick={formik.handleSubmit} label="Sign in" />
                <Link className={linkTextStyle} to="/resetPassword" onClick={() => window.scrollTo(0, 0)}>
                  Forgot your password?
                </Link>
              </div>
              <div className={registerLinkTextStyle}>
                <Link
                  className={cx(registerLinkTextStyle, linkStyle)}
                  to={{
                    pathname: '/signUp',
                    state: { redirect: location.pathname }
                  }}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  You don&apos;t have an account? Create it here
                </Link>
              </div>
            </div>
          </div>
        )
      }
    </Formik>
  );
});
