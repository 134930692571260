import React, { Fragment, useState } from 'react';
import { css } from 'emotion';
import { get } from 'lodash';
import tryAgain from '../../assets/tryAgain.svg';
import useFirebase from '../Firebase/useFirebase';
import { convertToBlob, downloadFile } from '../../utils/downloadFunctions';
import { DocumentButton } from '../components/DocumentButton';
import { documentsType, getDocumentName } from '../../constants/documents';

const documentContainer = css`
  padding: 1.5rem;
  border-bottom: 0.5px solid var(--gray);
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;
const errorContainer = css`
  padding-top: 1.5rem;
  display: flex;
`;

const documentTitle = css`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 0.75rem;
`;
const quoteButtonsContainer = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
  grid-gap: 1rem 0.5rem;
  :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const tryAgainButton = css`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;
const tryAgainContainer = css`
  display: flex;
  cursor: pointer;
  align-items: flex-start;
`;

const paragraphStyle = css`
  font-size: 1rem;
  letter-spacing: -0.03em;
  margin-right: 0.625rem;
`;

const tryAgainTextStyle = css`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-decoration-line: underline;
  color: var(--primaryBrand);
  margin-left: 0.25rem;
`;

export const DocumentQuoteContainer = props => {
  const { quoteState, setIsLoading } = props;
  const firebase = useFirebase();
  const [documentError, setDocumentError] = useState(false);
  const [lastDocumentDownload, setLastDocumentDownload] = useState({
    docId: undefined,
    downloadMethod: undefined
  });
  const handleQuoteDocumentDownload = async (docId, downloadMethod) => {
    try {
      setIsLoading(true);
      const { base64Content, contentType, filename } = await downloadMethod(docId, quoteState.quoteRef);

      const blob = convertToBlob(base64Content, contentType);
      setIsLoading(false);
      setDocumentError(false);
      downloadFile(blob, filename);
    } catch (err) {
      console.error(err);
      setDocumentError(true);
      setLastDocumentDownload({ docId, downloadMethod });
      setIsLoading(false);
    }
  };
  const handleTryAgain = async () => {
    if (lastDocumentDownload)
      await handleQuoteDocumentDownload(lastDocumentDownload.docId, lastDocumentDownload.downloadMethod);
  };
  return (
    <div className={documentContainer}>
      <div className={documentTitle}>Documents</div>
      {!documentError && (
        <div className={quoteButtonsContainer}>
          {quoteState.quoteDocuments?.map(doc => (
            <Fragment key={doc.id}>
              {get(documentsType[doc.type.code], doc.subType.code) && (
                <DocumentButton
                  name={getDocumentName(doc, 'QUOTE', quoteState.productCode)}
                  onClick={() =>
                    handleQuoteDocumentDownload(doc.id, () => firebase.doDownloadDocument(doc.id, 'QUOTE'))
                  }
                />
              )}
            </Fragment>
          ))}
        </div>
      )}
      {documentError && (
        <div className={errorContainer}>
          <p className={paragraphStyle}>We encountered an issue while loading documents for this policy.</p>
          <div className={tryAgainContainer} onClick={handleTryAgain}>
            <img src={tryAgain} alt="tryAgain" className={tryAgainButton} />
            <p className={tryAgainTextStyle}>Try again</p>
          </div>
        </div>
      )}
    </div>
  );
};
