import React, { Fragment } from 'react';
import { css } from 'emotion';
import { groupBy, get } from 'lodash';
import moment from 'moment';
import useFirebase from '../Firebase/useFirebase';
import { convertToBlob, downloadFile } from '../../utils/downloadFunctions';
import { DocumentButton } from '../components/DocumentButton';
import { documentsType, getDocumentName } from '../../constants/documents';
import { COVERMYTOOLS, BEAUTY } from '../../constants/products';

const policyButtonsContainer = css`
  display: flex;
  flex-direction: column;
  > h3 {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.2em;
    letter-spacing: -0.03em;
    margin-bottom: 0.5rem;
    color: #242427;
  }
  > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
    grid-gap: 1rem 0.5rem;
    :not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`;

const documentContainer = css`
  padding: 1.5rem;
  border-bottom: 0.5px solid var(--gray);
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const documentTitle = css`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 0.75rem;
`;

export const DocumentPolicyContainer = props => {
  const { policy, setIsLoading } = props;
  const firebase = useFirebase();
  const handlePolicyDocumentDownload = async (docId, downloadMethod) => {
    try {
      setIsLoading(true);
      const { base64Content, contentType, filename } = await downloadMethod(docId);
      const blob = convertToBlob(base64Content, contentType);
      setIsLoading(false);
      downloadFile(blob, filename);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };
  const groupedDocuments = groupBy(policy.policyDocuments, val => {
    if (['MANUAL_RENEWAL', 'AUTO_RENEWAL', 'RENEWAL_ACCEPT'].includes(val.type.code)) {
      return 'Renewal Policy Documents';
    }
    if (val.type.code === 'MTA_ACCEPT') {
      return `Updated Policy Documents ${moment(val.created).format('DD/MM/YYYY')}`;
    }
    if (val.type.code === 'QUOTE' && [COVERMYTOOLS, BEAUTY].includes(policy.productCode)) {
      return 'Policy Documents';
    }
    if (val.type.code === 'QUOTE') {
      return 'Quote Documents';
    }
    return 'Policy Documents';
  });
  return (
    <div className={documentContainer}>
      <div className={documentTitle}>Documents</div>
      <div className={policyButtonsContainer}>
        {Object.entries(groupedDocuments).map(([key, value]) => (
          <Fragment key={key}>
            {key !== 'Quote Documents' && (
              <>
                <h3>{key}</h3>
                <div>
                  {value.map(doc => (
                    <Fragment key={doc.id}>
                      {get(documentsType[doc.type.code], doc.subType.code) && (
                        <DocumentButton
                          name={getDocumentName(doc, 'POLICY', policy.productCode)}
                          onClick={() =>
                            handlePolicyDocumentDownload(doc.id, () => firebase.doDownloadDocument(doc.id, 'POLICY'))
                          }
                        />
                      )}
                    </Fragment>
                  ))}
                </div>
              </>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
