import get from 'lodash/get';

export class LookupStore {
  devices = undefined;

  deviceModels = undefined;

  makesByDevice = undefined;

  titles = undefined;

  axisTreatments = undefined;

  pbisParkHomeMake = undefined;

  setLookups = lookups => {
    const { deviceModels, makesByDevice, devices, titles, axisTreatments, pbisParkHomeMake } = lookups;
    this.titles = titles;
    this.deviceModels = deviceModels;
    this.makesByDevice = makesByDevice;
    this.devices = devices;
    this.axisTreatments = axisTreatments;
    this.pbisParkHomeMake = pbisParkHomeMake;
  };

  getTitleNameFromPolicyholder = policyholder =>
    this.titles
      ? get(
          this.titles.find(element => element.code === get(policyholder, 'title.code')),
          'value'
        )
      : '';
}

export const lookupStore = new LookupStore();
