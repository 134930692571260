import React from 'react';
import { css } from 'emotion';
import { Accordion } from '../components/Accordion';

const bodyContainer = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  padding: 2.2rem;
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const titleStyle = css`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 2.125rem;
`;

const bodyTextStyle = css`
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 0;
`;

export const CommonQuestions = () => (
  <div className={bodyContainer}>
    <h1 className={titleStyle}>Common Questions</h1>
    <Accordion
      title="How do I view my policy documents?"
      body={
        <p className={bodyTextStyle}>
          Policy documents can be viewed within the customer portal by clicking on MY POLICIES in the left-hand
          navigation banner, then click the policy for the documents you wish to view. Once the policy has opened, you
          will see your documents underneath the Documents section. These documents can all be clicked and downloaded to
          save or print for your records.
        </p>
      }
    />
    <Accordion
      title="How do I make a change to my policy?"
      body={
        <p className={bodyTextStyle}>
          To make a change to your policy you should click on MY POLICIES in the left-hand navigation banner, then click
          the policy for the policy you need to change. Once that policy has opened, you will see a button to MAKE A
          CHANGE. This will take you back through the buying process for you to overwrite your existing details.
        </p>
      }
    />
    <Accordion
      title="How do I make a claim on my policy?"
      body={
        <p className={bodyTextStyle}>
          To report a claim on your policy you should click on MY POLICIES in the left-hand navigation banner, then
          click the policy for the policy you need to report a claim. Once that policy has opened, you will see a button
          to REPORT A CLAIM. This will take you to the claims handlers instructions for your particular policy.
        </p>
      }
    />
    <Accordion
      title="How can I cancel my policy?"
      body={
        <p className={bodyTextStyle}>
          For most of our policies, cover can be cancelled within the first 14 days free of charge, without any reason
          and a full refund provided no claim has been made. After the 14 day cooling off period, we require 30 days’
          notice. Cancellations must be sent in writing via email or post. Please quote your policy number and state
          that you wish to cancel. You can email hello@insyncinsurance.co.uk or write to Insync Insurance Solutions Ltd,
          Unit 9 Albany Park, Cabot Lane, Poole, Dorset, BH17 7BX. Providing no claim has been made you will likely
          receive a proportionate refund of the annual premium for the remaining months. Please check your policy
          document for full terms and conditions.
        </p>
      }
    />
    <Accordion
      title="How soon will I receive my documents after purchasing a policy?"
      body={
        <p className={bodyTextStyle}>
          Your documents will be available immediately from our customer portal after purchasing a policy and
          registering for the portal.
        </p>
      }
    />
    <Accordion
      title="Can I buy a policy if I’m based outside of the UK?"
      body={
        <p className={bodyTextStyle}>
          Unfortunately, this is not possible. Our policies are intended for use by UK domiciled individuals and comply
          with appropriate UK legislation and regulation.
        </p>
      }
    />
    <Accordion
      title="Who is Insync Insurance Solutions Ltd?"
      body={
        <p className={bodyTextStyle}>
          Insync Insurance Solutions Ltd is an insurance broker based in Poole, Dorset. Insync work on behalf of
          insurance underwriters to provide cover to our customers. Insync Insurance Solutions Ltd is authorised and
          regulated by the Financial Conduct Authority.
        </p>
      }
    />
    <Accordion
      title="Who is Covermytools, Mobiru and Insync Beauty?"
      body={
        <p className={bodyTextStyle}>
          Covermytools, Mobiru and Insync Beauty are all registered trading styles of Insync Insurance Solutions Ltd.
        </p>
      }
    />
    <Accordion
      title="In the event I make a claim, how much is the excess?"
      body={
        <p className={bodyTextStyle}>
          This is dependent on the policy you have. Please check your policy documents for full terms and conditions.
        </p>
      }
    />
    <Accordion
      title="When does my insurance policy end?"
      body={
        <p className={bodyTextStyle}>
          Your policy end date can be found on your policy documents or by clicking on MY POLICIES in the left-hand
          navigation banner, then click the policy for the end date you wish to check. Once that policy has opened, you
          will see your policy end date listed.
        </p>
      }
    />
    <Accordion
      title="Do I need to supply a receipt if I make a claim?"
      body={
        <p className={bodyTextStyle}>
          In most cases, yes, a receipt or proof of purchase will need to be supplied where appropriate when making a
          claim.
        </p>
      }
    />
    <Accordion
      title="How do I manage my loan repayments?"
      body={
        <p className={bodyTextStyle}>
          If you have paid by Close Brothers, you will receive documents in the post that detail your loan agreement and
          include your loan reference number. To manage your loan, manage your Direct Debit and make the relevant
          changes you can do it from the{' '}
          <a href="https://www.closebrothersbanking.com/web/close-brothers/registration">Close Brothers Portal</a>.
        </p>
      }
    />
  </div>
);
