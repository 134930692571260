import React from 'react';
import { css, cx } from 'emotion';
import fileIcon from '../../assets/fileImage.png';

const policyButtonStyle = css`
  background: unset;
  border: none;
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: -0.03em;
  color: var(--dark);
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }

  &:hover .policyTextStyle:after {
    opacity: 1;
    width: 100%;
  }
`;

const policyTextStyle = css`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    height: 2px;
    left: 50%;
    width: 0;
    opacity: 0;
    background: var(--primaryBrand);
    transform: translateX(-50%);
    transition: width 0.25s, opacity 0.25s;
  }
`;
const fileIconStyle = css`
  height: 1.5rem;
  margin-right: 0.25rem;
`;
export const DocumentButton = props => {
  const { name, onClick, customStyle } = props;
  return (
    <button type="button" className={cx(policyButtonStyle, customStyle)} onClick={onClick}>
      <img src={fileIcon} alt="fileIcon" className={fileIconStyle} />
      <div className={cx(policyTextStyle, 'policyTextStyle')}>{name}</div>
    </button>
  );
};
