import React from 'react';
import { useFormikContext, useField } from 'formik';
import { css, cx } from 'emotion';

const switchContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const switchLabelStyle = css`
  font-family: 'Open Sans', sans-serif;
  color: var(--dark);
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: -0.02em;
  width: 80%;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const switchStyle = css`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;

const toggleStyle = css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 0.75rem;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:before {
    position: absolute;
    content: '';
    height: 1rem;
    width: 1rem;
    left: 0.25rem;
    bottom: 0.25rem;
    background-color: var(--white);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const checkInputStyle = css`
  :checked + .toggle {
    background-color: var(--primaryBrand);
  }
  :checked + .toggle:before {
    -webkit-transform: translateX(1.5rem);
    -ms-transform: translateX(1.5rem);
    transform: translateX(1.5rem);
  }
`;

const SwitchFieldComponent = props => {
  const { value, setFieldValue, label } = props;
  return (
    <div className={switchContainerStyle}>
      <div className={switchLabelStyle}>{label}</div>
      <label className={switchStyle}>
        <input checked={!!value} type="checkbox" className={checkInputStyle} onChange={() => setFieldValue(!value)} />
        <span className={cx(toggleStyle, 'toggle')} />
      </label>
    </div>
  );
};

export const SwitchField = props => {
  const { label, name, setChanged } = props;

  const formik = useFormikContext();
  const [field] = useField(name);
  const { setFieldValue } = formik;

  return (
    <SwitchFieldComponent
      label={label}
      name={name}
      value={field.value}
      setFieldValue={value => {
        setChanged(true);
        setFieldValue(name, value);
      }}
    />
  );
};
