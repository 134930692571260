import React from 'react';
import { css } from 'emotion';
import { BEAUTY, COVEATRADESMAN, PBIS } from '../../constants/products';

const messageStyle = css`
  border-top: 0.5px solid var(--gray);
  padding: 1.5rem;

  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const marginBottom = css`
  margin-bottom: 1rem;
`;

const boldText = css`
  font-weight: bold;
  color: var(--dark);

  &:hover {
    color: var(--dark);
    text-decoration: none;
  }
`;

export const PolicyMessageDisclaimer = props => {
  const { policy } = props;
  return (
    <>
      {policy.isCancelled && policy.productCode === PBIS && (
        <div className={messageStyle}>
          <div>
            Your policy has been cancelled. If you would like to reinstate it please email
            <a className={boldText} href="mailto:enquiries@pbinsurance.co.uk">
              {' '}
              enquiries@pbinsurance.co.uk
            </a>
            .
          </div>
        </div>
      )}
      {policy.isCancelled && policy.productCode !== PBIS && (
        <div className={messageStyle}>
          <div>
            Your policy has been cancelled. If you would like to reinstate it please email
            <a className={boldText} href="mailto:hello@insyncinsurance.co.uk">
              {' '}
              hello@insyncinsurance.co.uk
            </a>
            .
          </div>
        </div>
      )}
      {policy.productCode === COVEATRADESMAN && !policy.isCancelled && (
        <div className={messageStyle}>
          <p className={marginBottom}>
            To notify us of a claim, please email us on{' '}
            <a className={boldText} href="mailto:newcommercialclaims@coveainsurance.co.uk">
              newcommercialclaims@coveainsurance.co.uk
            </a>{' '}
            or call us on{' '}
            <a className={boldText} href="tel:0330 024 2266">
              0330 024 2266
            </a>{' '}
            with details of the incident.
          </p>
          <div>
            To cancel your policy please email{' '}
            <a className={boldText} href="mailto:hello@insyncinsurance.co.uk">
              hello@insyncinsurance.co.uk
            </a>{' '}
            stating your policy number and the reason for your cancellation.
          </div>
        </div>
      )}
      {policy.productCode === BEAUTY && !policy.isCancelled && (
        <div className={messageStyle}>
          <p className={marginBottom}>
            If you need to Report a claim for your Insync Beauty policy please use the 24 hour claims helpline on
            <a className={boldText} href="tel:0345-300-4006">
              {' '}
              0345 300 4006
            </a>{' '}
            stating your policy number.
          </p>
          <div>
            To cancel your policy please email
            <a className={boldText} href="mailto:hello@insyncinsurance.co.uk">
              {' '}
              hello@insyncinsurance.co.uk{' '}
            </a>
            stating your policy number and the reason for your cancellation.
          </div>
        </div>
      )}
      {policy.productCode === PBIS && !policy.isCancelled && (
        <div className={messageStyle}>
          To cancel your policy please email{' '}
          <a className={boldText} href="mailto:enquiries@pbinsurance.co.uk">
            enquiries@pbinsurance.co.uk
          </a>{' '}
          stating your policy number and the reason for your cancellation.
        </div>
      )}
      {![BEAUTY, COVEATRADESMAN, PBIS].includes(policy.productCode) && !policy.isCancelled && (
        <div className={messageStyle}>
          To cancel your policy please email{' '}
          <a className={boldText} href="mailto:hello@insyncinsurance.co.uk">
            hello@insyncinsurance.co.uk
          </a>{' '}
          stating your policy number and the reason for your cancellation.
        </div>
      )}
    </>
  );
};
