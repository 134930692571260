import React, { useEffect, useRef, useState } from 'react';
import { css } from 'emotion';
import autoAnimate from '@formkit/auto-animate';
import arrowUp from '../../assets/angle-up.png';
import arrowDown from '../../assets/angle-down.png';

const containerStyle = css`
  width: 100%;
  background-color: var(--white);
  border-radius: 8px;
  margin-bottom: 1.5rem;
`;

const headerStyle = css`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0.75rem 0.75rem 1.5rem;
  background-color: unset;
  border: none;
  cursor: pointer;
  width: 100%;
  @media (max-width: 767px) {
    padding: 0.75rem 0.75rem 0.75rem 1rem;
  }
`;

const titleStyle = css`
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: -0.03em;
  margin: 0.25rem 0;
`;

const bodyStyleOpened = css`
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  @media (max-width: 767px) {
    padding: 0.5rem 1rem 1.5rem 1rem;
  }
`;

const iconStyle = css`
  height: 2rem;
`;

export const Accordion = props => {
  const [opened, setOpened] = useState(false);
  const parent = useRef(null);
  const { title, body } = props;
  useEffect(() => {
    if (parent.current) {
      autoAnimate(parent.current);
    }
  }, [parent]);

  return (
    <div className={containerStyle} ref={parent}>
      <button type="button" className={headerStyle} onClick={() => setOpened(!opened)}>
        <h1 className={titleStyle}>{title}</h1>
        <img className={iconStyle} alt="arrow" src={opened ? arrowUp : arrowDown} />
      </button>
      {opened && (
        <div className={bodyStyleOpened}>
          <p>{body}</p>
        </div>
      )}
    </div>
  );
};
