import React from 'react';
import { css } from 'emotion';

const bodyContainer = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  padding: 2.2rem;
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const titleStyle = css`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 2.125rem;
`;

const textStyle = css`
  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  width: 63%;
  color: var(--dark);
  margin-bottom: 2rem;
  @media (max-width: 767px) {
    width: 90%;
  }
`;

const linkStyle = css`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 0.75rem;
  &:hover {
    color: var(--dark);
    text-decoration: none;
  }
`;

const orangeText = css`
  color: var(--primaryBrand);
`;

export const ContactUs = () => (
  <div className={bodyContainer}>
    <h1 className={titleStyle}>Contact Us</h1>
    <p className={textStyle}>
      If you have any questions about your policy or anything to do with your account please get in touch with our
      dedicated team of advisors.
    </p>
    <a className={linkStyle} href="mailto:hello@insyncinsurance.co.uk">
      e. <span className={orangeText}>hello@insyncinsurance.co.uk</span>
    </a>
    <a className={linkStyle} href="tel:0330-124-0730">
      t. <span className={orangeText}>0330 124 0730</span>
    </a>
  </div>
);
