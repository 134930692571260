import { AXIS_AESTHETIC } from './products';

export const documentsType = {
  NB: {
    NB_DOC_ACCEPT_LETTER: 'Policy Letter',
    NB_DOC_ACCEPT_WORDING: 'Policy Wording',
    NB_DOC_ACCEPT_SUMMARY: 'Policy Summary',
    NB_DOC_ACCEPT_SCHEDULE: 'Policy Schedule',
    NB_DOC_ACCEPT_SOF: 'Policy Statement Of Facts',
    NB_DOC_ACCEPT_ADDONS: 'Add on Covers',
    NB_DOC_LETTER: 'Policy Letter',
    NB_DOC_ACCEPT_POC: 'Proof Of Cover Letter',
    NB_DOC_ACCEPT_ELCERT: 'Employers Liability Certificate',
    NB_DOC_ACCEPT_IPID: 'Insurance Product Information Document',
    NB_DOC_ACCEPT_LEGAL_WORDING: 'General Liability Wording',
    NB_DOC_ACCEPT_SUMMARY_OF_CHANGE: 'Summary Of Change'
  },
  MTA_ACCEPT: {
    MTA_DOC_ACCEPT_LETTER: 'MTA Acceptance Letter',
    MTA_DOC_ACCEPT_WORDING: 'MTA Policy Wording',
    MTA_DOC_ACCEPT_SUMMARY: 'MTA Policy Summary',
    MTA_DOC_ACCEPT_SCHEDULE: 'MTA Policy Schedule',
    MTA_DOC_ACCEPT_ADDONS: 'MTA Add on',
    MTA_DOC_ACCEPT_SOF: 'MTA Policy SOF',
    MTA_DOC_ACCEPT_ELCERT: 'MTA Employers Liability Certificate',
    MTA_DOC_ACCEPT_LEGAL_WORDING: 'AXIS Combined GL Wording MTA'
  },
  QUOTE: {
    QUOTE_DOC_ACCEPT_WORDING: 'Quote Wording',
    QUOTE_DOC_INVITE_SUMMARY: 'Quote Summary',
    QUOTE_DOC_ACCEPT_SCHEDULE: 'Quote Schedule',
    QUOTE_DOC_SOF: 'Quote Statement Of Facts',
    QUOTE_DOC_ADDONS: 'Add on Covers',
    QUOTE_DOC_LETTER: 'Quote Letter',
    QUOTE_DOC_ACCEPT_IPID: 'Policy IPID',
    QUOTE_DOC_ACCEPT_ELCERT: 'Quote Employers Liability Certificate',
    QUOTE_DOC_ACCEPT_LEGAL_WORDING: 'Quote General Liability Wording',
    QUOTE_DOC_SUMMARY_OF_CHANGE: 'Summary Of Change'
  },
  CANCELLATION: {
    CANX_DOC_ACCEPT_SCHEDULE: 'Cancellation Policy Schedule',
    CANX_DOC_ACCEPT_LETTER: 'Cancellation Policy Letter'
  },
  MANUAL_RENEWAL: {
    MR_DOC_INVITE_WORDING: 'Renewal Invite Wording',
    MR_DOC_INVITE_SUMMARY: 'Renewal Invite Summary',
    MR_DOC_INVITE_SCHEDULE: 'Renewal Invite Schedule',
    MR_DOC_INVITE_SOF: 'Renewal Invite Statement Of Facts',
    MR_DOC_INVITE_LETTER: 'Renewal Invite  Letter',
    MR_DOC_INVITE_ADDONS: 'Renewal Invite Add on Covers',
    MR_DOC_INVITE: 'Renewal Invite'
  },
  AUTO_RENEWAL: {
    AR_DOC_INVITE_WORDING: 'Renewal Invite Wording',
    AR_DOC_INVITE_SUMMARY: 'Renewal Invite Summary',
    AR_DOC_INVITE_SCHEDULE: 'Renewal Invite Schedule',
    AR_DOC_INVITE_SOF: 'Renewal Invite Statement Of Facts',
    AR_DOC_INVITE_LETTER: 'Renewal Invite Letter',
    AR_DOC_INVITE_ADDONS: 'Renewal Invite Add on Covers',
    AR_DOC_INVITE: 'Renewal Invite'
  },
  RENEWAL_ACCEPT: {
    RA_DOC_ACCEPT_WORDING: 'Renewal Acceptance Wording',
    RA_DOC_ACCEPT_SUMMARY: 'Renewal Acceptance Summary',
    RA_DOC_ACCEPT_SCHEDULE: 'Renewal Acceptance Schedule',
    RA_DOC_ACCEPT_SOF: 'Renewal Acceptance Statement Of Facts',
    RA_DOC_ACCEPT_LETTER: 'Renewal Acceptance Letter',
    RA_DOC_ACCEPT_ADDONS: 'Renewal Acceptance Add on Covers',
    RA_DOC_ACCEPT: 'Renewal Acceptance',
    RA_DOC_ACCEPT_ELCERT: 'Renewal Employers Liability Certificate'
  }
};

export const getDocumentName = (documentType, policyType, product) => {
  let documentName = documentsType[documentType.type.code][documentType.subType.code];
  if (!documentName) {
    return;
  }
  if (policyType === 'POLICY') {
    documentName = documentName.replace('Quote', 'Policy');
  }
  if (policyType === 'QUOTE') {
    documentName = documentName.replace('Policy', 'Quote');
  }
  if (product === AXIS_AESTHETIC && policyType === 'QUOTE' && documentName === 'Quote Statement Of Facts') {
    documentName = 'Quote Statement Of Facts & Treatment List';
  }
  if (product === AXIS_AESTHETIC && policyType === 'POLICY' && documentName === 'Policy Statement Of Facts') {
    documentName = 'Policy Statement Of Facts & Treatment List';
  }
  // eslint-disable-next-line consistent-return
  return documentName;
};
