import React from 'react';
import { css } from 'emotion';
import { NewPolicyCard } from '../components/NewPolicyCard';
import { Loader } from '../components/Loader';
import { QuoteCard } from '../components/QuoteCard';
import { useUserQuotes } from '../../api/api';
import { ErrorQuoteCard } from '../components/ErrorQuoteCard';

const bodyContainer = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  padding: 2.2rem;
  @media (max-width: 767px) {
    padding: 1rem;
  }
`;

const cardContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
  flex: 1;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const titleStyle = css`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: var(--dark);
  margin-bottom: 2.125rem;
`;

export const MyQuotes = () => {
  const { isLoading, data: quotes } = useUserQuotes();
  return (
    <div className={bodyContainer}>
      <h1 className={titleStyle}>My Quotes</h1>
      <div className={cardContainer}>
        {isLoading ? (
          <Loader />
        ) : quotes && quotes.length > 0 ? (
          <>
            {quotes.map(quote =>
              quote.status === 'error' ? (
                <ErrorQuoteCard quote={quote} key={quote.quoteRef} />
              ) : (
                <QuoteCard quote={quote} key={quote.quoteRef} />
              )
            )}
            <NewPolicyCard quote />
          </>
        ) : (
          <NewPolicyCard quote />
        )}
      </div>
    </div>
  );
};
