import React from 'react';
import { css, cx } from 'emotion';
import moment from 'moment';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { PBIS, schemeInformation } from '../../constants/products';
import insyncDashedQuote from '../../assets/insyncBeautyBorder.svg';
import insyncLogo from '../../assets/insyncLogoSecundary.png';

const containerStyle = css`
  padding: 1rem;
  border-radius: 8px;
  background: var(--white);
  min-width: 23.125rem;
  margin-bottom: 1.5rem;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    box-shadow: 0 8px 40px rgba(64, 64, 66, 0.08);
  }
  @media (max-width: 767px) {
    min-width: 100%;
  }
`;

const logoStyle = css`
  height: 1.5rem;
  align-self: flex-start;
  margin-right: 0.75rem;
`;

const contentStyle = color => css`
  display: flex;
  flex: 1;
  &:before {
    content: '';
    display: block;
    width: 4px;
    background-color: ${color};
    -webkit-mask-image: url(${insyncDashedQuote});
    mask-image: url(${insyncDashedQuote});
    border-radius: 3px;
    margin-right: 1.5rem;
  }
`;
const infoContainerStyle = css`
  display: flex;
  flex-direction: column;
`;

const productTitleStyle = css`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: var(--dark);
`;
const rowStyle = css`
  margin-bottom: 1rem;
`;

const inlineBlock = css`
  display: inline-block;
`;

const categoryNameStyle = css`
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: -0.03em;
  color: var(--darkGray);
  margin-right: 0.75rem;
`;

const categoryInfoStyleS = css`
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.85rem;
  letter-spacing: -0.03em;
  color: var(--dark);
`;

const quoteNoStyle = css`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: var(--dark);
`;

const cancelledStyle = css`
  opacity: 0.5;
  display: flex;
  flex: 1;
  filter: grayscale(100%);
  &:before {
    content: '';
    display: block;
    width: 4px;
    background: var(--darkGray);
    border-radius: 3px;
    margin-right: 1.5rem;
  }
`;

export const QuoteCard = props => {
  const { quote } = props;
  const { productCode, expiryDate, coverStartDate, quoteRef, price } = quote;

  return (
    <Link className={containerStyle} to={`/quotes/${quoteRef}`} onClick={() => window.scrollTo(0, 0)}>
      <div
        className={
          quote.isCancelled
            ? cancelledStyle
            : contentStyle(get(schemeInformation[productCode], 'headerColor') || '#ffc600')
        }
      >
        <div className={infoContainerStyle}>
          <div className={rowStyle}>
            <img
              src={get(schemeInformation[productCode], 'logo') || insyncLogo}
              alt="insyncLogo"
              className={cx(logoStyle, inlineBlock)}
            />
            <div className={cx(productTitleStyle, inlineBlock)}>{get(schemeInformation[productCode], 'name')}</div>
          </div>
          <div>
            <div className={cx(categoryNameStyle, inlineBlock)}>Start Date</div>
            <div className={cx(categoryInfoStyleS, inlineBlock)}>{moment(coverStartDate).format('DD/MM/YYYY')}</div>
          </div>
          <div className={rowStyle}>
            <div className={cx(categoryNameStyle, inlineBlock)}>Expiry Date</div>
            <div className={cx(categoryInfoStyleS, inlineBlock)}>{moment(expiryDate).format('DD/MM/YYYY')}</div>
          </div>
          {productCode === PBIS ? (
            <>
              <div className={cx(categoryNameStyle, inlineBlock)}>Quote Reference</div>
              <div className={quoteNoStyle}>{quoteRef}</div>
            </>
          ) : (
            <>
              <div className={cx(categoryNameStyle, inlineBlock)}>Quote Price</div>
              <div className={quoteNoStyle}>
                £
                {(
                  parseFloat(get(price, 'grossPremiumAmountIncTax.amount')) + parseFloat(get(price, 'fees.amount'))
                ).toFixed(2)}
              </div>
            </>
          )}
        </div>
      </div>
    </Link>
  );
};
